.main-container-task-dialog{
    border: 0.5px solid black;
    height: 100%;
}

.project-field-task-dialog{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    gap: 1px;
    align-items: center; /* Horizontally center content */
    color: black;
    height: fit-content;
}

.my-task-dialog-row1,
.my-task-dialog-row2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 92%;
    margin-top: 10px;
    margin-bottom: 20px;
    gap: 20px;
    color: black !important;
}

.field_task-id{
    flex: 1;
}
.field_task-description{
    flex: 5;
}
.field_task-start-date{
    flex: 3;
}
.field_task-duration{
    flex: 1;   
}
.field_task-progress{
    flex: 1;
}

/* To specify height and font size */
.e-input:not(:valid), .e-input:valid, .e-float-input.e-control-wrapper input:not(:valid), .e-float-input.e-control-wrapper input:valid, .e-float-input input:not(:valid), .e-float-input input:valid, .e-input-group input:not(:valid), .e-input-group input:valid, .e-input-group.e-control-wrapper input:not(:valid), .e-input-group.e-control-wrapper input:valid, .e-float-input.e-control-wrapper textarea:not(:valid), .e-float-input.e-control-wrapper textarea:valid, .e-float-input textarea:not(:valid), .e-float-input textarea:valid, .e-input-group.e-control-wrapper textarea:not(:valid), .e-input-group.e-control-wrapper textarea:valid, .e-input-group textarea:not(:valid), .e-input-group textarea:valid {
    font-size: 16px;
    height: 40px;
    color: rgb(96,172,86);
    font-weight: bolder;

}
