.projects_container{
    display: flex;
    width: 100%;
    position: sticky;
    top: 87px;
    height: 86vh;
}
.projects_sidebar {
    position: sticky;
    top: 100px;
    width: 7%;
    min-width: 7%;
    max-width: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    border: none;
    height: 100%;
    background-color: whitesmoke;
}

.projects_main{
    width: 93%;
    margin-left: 10px;
    margin-top: 10px;
    
}
