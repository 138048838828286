.this-week-whereabouts >h3{
    background-color: rgba(201,201,201,47%) !important;
    font-size: 15px;
    color: rgb(96,172,86);
    width: 99%;
    padding: 10px 0px;
    text-align: center; /* Center the text within h3 tags */
}

.field-line-this-week-whereabouts {
    width: 99%;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontally center content */
    min-height: 100vh; /* Ensure the container takes up at least the full viewport height */
}

.this-week-whereabouts{
    width: 100%;
    margin-left: 0px;
    align-items: center; /* Horizontally center content */
    justify-content: center; /* Vertically center content */
    min-height: 100vh; /* Ensure the container takes up at least the full viewport height */
}

.button-container-whereabouts {
    display: flex;
    gap: 60px;
    
  }
  .button-container-whereabouts-subA1 {
    display: flex;
    gap: 10px;    
  }
  .button-container-whereabouts-subA1 button {
    display: flex;
    align-items: center; /* Center vertically */
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    font-size: 14px !important;
  }

  .button-container-whereabouts-subA1 button:hover {
    background-color: lightgray; /* Change the background color to grey on hover */
  }
  .add-button-whereabouts {
    color:rgb(96, 172, 86) ;
    cursor: pointer;
  }
  .edit-button-whereabouts {
    color:rgb(172, 96, 86) ;
    cursor: pointer;
  }
  .delete-button-whereabouts {
    color:red ;
    cursor: pointer;
  }

  .button-container1 {
    display: flex;   
  }

  .but-icon-whereabouts{
    margin-right: 10px;
    font-size: 16PX !important;
  }
  
  .disabled-button {
    color: lightgrey;
    pointer-events: none !important; /* Disable pointer events to prevent hover and click */
    cursor: not-allowed;
  }