.e-tab .e-tab-header .e-toolbar-item.e-active {
    background: rgba(201,201,201,47%) !important;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
    color: green !important;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
    background: rgba(201,201,201,47%) !important;
}
.main-tab-container{
    margin-left: 20px !important;
}
