.outer-change-container >h3{
    background-color: rgba(201,201,201,47%) !important;
    font-size: 15px;
    color: rgb(96,172,86);
    width: 100%;
    padding: 10px 0px;
    text-align: center; /* Center the text within h3 tags */
}

.main-change-containers{
    width: 99%;
    height: 80vh;
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: center; /* Horizontally center content */
    justify-content: center; /* Vertically center content */
}

.outer-change-container {
  box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
  height: 100%;
  width: 49%;
}
.inner-change-container {
  width: 100%;
  height: 100%;
}

.button-container {
    display: flex;
    gap: 60px;
    
  }
  .button-container-subA1,
  .button-container-subA2,
  .button-container-subA3 {
    display: flex;
    gap: 10px;    
  }
  .button-container-subA1 button,
  .button-container-subA3 button,
  .button-container-subA2 button {
    display: flex;
    align-items: center; /* Center vertically */
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    font-size: 14px !important;
  }

  .button-container-subA1 button:hover,
  .button-container-subA3 button:hover,
  .button-container-subA2 button:hover {
    background-color: lightgray; /* Change the background color to grey on hover */
  }
  
  .add-button {
    color:rgb(96, 172, 86) ;
    cursor: pointer;
  }
  .edit-button {
    color:rgb(172, 96, 86) ;
    cursor: pointer;
  }
  .coordinator-button {
    color:rgb(86, 96, 172) ;
    cursor: pointer;
  }
  
  .administrator-button {
    color: rgb(86, 172, 96);
    cursor: pointer;
  }
  
  .facilitator-button {
    color: rgb(172, 86, 172);
    cursor: pointer;
  }
  
  .national-facilitator-button {
    color: rgb(86, 172, 172);
    cursor: pointer;
  }
  
  .assistant-nf-button {
    color: rgb(172, 172, 86);
    cursor: pointer;
  }

  
  .stage-approval-button {
    color: green;
    cursor: pointer;
  }

  .disabled-button {
    color: grey;
    pointer-events: none !important; /* Disable pointer events to prevent hover and click */
    cursor: not-allowed;
  }

  .send-icon{
    margin-right: 10px;
    font-size: 16PX !important;
  }