
.my-to-do-tasks >h3{
    background-color: rgba(201,201,201,47%) !important;
    font-size: 15px;
    color: rgb(96,172,86);
    width: 99%;
    padding: 10px 0px;
    text-align: center; /* Center the text within h3 tags */
}

.field-line-my-tasks{
    width: 99%;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontally center content */
    min-height: 100vh; /* Ensure the container takes up at least the full viewport height */
}

.my-to-do-tasks{
    width: 100%;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontally center content */
    justify-content: center; /* Vertically center content */
    min-height: 100vh; /* Ensure the container takes up at least the full viewport height */
}


