
.national-reports-container1{
    display: flex;
    padding: 0px 0px; 
    flex-direction: column; /* Align chart rows vertically */
    align-items: center; /* Center horizontally within the container */
    height: fit-content; /* Set the height to 90% of the viewport height */
    color: rgb(96,172,86);
    background-color: rgba(96,172,86, 20%);
    margin-right: 0px;
    width: 110%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }
  
  .chart-row1,
  .chart-row2,
  .chart-row3,
  .chart-row4 {
    display: flex;
    gap: 10px; /* Add horizontal space between chart containers */
    width: 98%;
    height: 50%;
    padding: 10px;
  }
  
  .chart-cont1,
  .chart-cont2,
  .chart-cont3,
  .chart-cont4,
  .chart-cont5, 
  .chart-cont6, 
  .chart-cont7, 
  .chart-cont8, 
  .chart-cont9, 
  .summary-container1,
  .summary-container2  {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    background-color:whitesmoke;
    border-radius: 15px; 
    color: rgba(96,172,86, 60%);  
  }
  .chart-cont1 {
    flex: 1;  
  }
  .chart-cont2 {
    flex: 1;
  }
  .chart-cont3 {
    flex: 1;
  }
  .chart-cont4 {
    flex: 1;
  }
  .chart-cont5 {
    flex: 1;
  }
  .chart-cont6 {
    flex: 1;
  }

  .chart-cont7 {
    flex: 1;  
  }
  .chart-cont8 {
    flex: 1;
  }
  .chart-cont9 {
    width: 100%;
  }
  .summary-container1{
    flex: 1.5;
    margin-top: 15px;
  }

  .summary-container2{
    flex: 2;
    margin-top: 15px;
  }

  .summary-container1 h1,
  .summary-container2 h1{
    margin-top: 15px;
    margin-bottom: 10px;
    color: #008080;
  }

  .summary-container1 h2,
  .summary-container2 h2{
    color: rgba(96,172,86, 50%);
  }

  .chart-container h2,
  .chart-row h2 {
    margin: 0;
  }
  