.headerOption{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
}
.headerOption:hover{
    color: rgba(96,172,86,50%);
    border-radius: 10px;
}
.headerOption_title{
    font-size: 15px;
    margin-top: 5px;
}
.custom_icon{
    font-size: 30px !important;
}