.all-projects >h3,
.workflow-history-all-projects >h3{
    background-color: rgba(201,201,201,47%) !important;
    font-size: 15px;
    color: rgb(96,172,86);
    width: 100%;
    padding: 10px 0px;
    text-align: center; /* Center the text within h3 tags */
}

.project-all-projects-container{
    width: 68%;
    height: 590px;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center content */
    min-height: 80vh; /* Ensure the container takes up at least the full viewport height */
}

.workflow-history-all-projects{
    width: 28%;
    margin-left: 10px;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    height: 100%;
}

.main-all-projects-container{
    width: 100%;
    height: 600px;
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: center; /* Horizontally center content */
    justify-content: center; /* Vertically center content */
    min-height: 80vh; /* Ensure the container takes up at least the full viewport height */
}

.all-projects {
  box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
  width: 100%;
  height: 100%;
}