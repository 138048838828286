.compli-outer-container{
    border: 0.5px solid black;
    height: fit-content;
}

.compli-inner-container{
    display: flex;
    flex-direction: column;
    width: 99%;
    margin-left: 5px;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    gap: 10px;
    align-items: center; /* Horizontally center content */
    height: fit-content;
    padding-bottom: 30px;
    background-color: rgba(201,201,201,47%);
}

.compli-row1,
.compli-row2,
.compli-row3,
.compli-row4,
.compli-row5 {
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 98%;
  margin-top: 30px;
}
.compli-id{
    flex: 1;
    margin-left: 20px;
}
.compli-name{
    flex: 3;
    margin-left: 20px;
}
.compli-num{
    flex: 2;
    margin-left: 20px;
}
.compli-status{
    flex: 2;
    margin-left: 20px;
}

.compli-reg_number{
    flex: 1;
    margin-left: 20px;
}
.compli-reg_status{
    flex: 1;
    margin-left: 20px;
}
.compli-tax_number{
    flex: 1;
    margin-left: 20px;
}
.compli-vat_number{
    flex: 1;
    margin-left: 20px;
}

.compli-contact_person{
    flex: 1;
    margin-left: 20px;
}
.compli-telephone{
    flex: 0.5;
    margin-left: 20px;
}
.compli-email_address1{
    flex: 1.5;
    margin-left: 20px;
}
.compli-company_website{
    flex: 1;
    margin-left: 20px;
}

.compli-active_bank{
    flex: 0.5;
    margin-left: 20px;
}
.compli-account_holder{
    flex: 1;
    margin-left: 20px;
}
.compli-bank_Name{
    flex: 1;
    margin-left: 20px;
}
.compli-account_number{
    flex: 1;
    margin-left: 20px;
}

.compli-account_type{
    flex: 1;
    margin-left: 20px;
}
.compli-branch_name{
    flex: 1;
    margin-left: 20px;
}
.compli-branch_code{
    flex: 1;
    margin-left: 20px;
}
.compli-town{
    flex: 1;
    margin-left: 20px;
}
.compli-physcal_address{
    flex: 1;
    margin-left: 20px;
}
.compli-postal_address{
    flex: 1;
    margin-left: 20px;
}

.compli-general_comment{
    flex: 1;
    margin-left: 20px;
}

/* To specify height and font size */
.e-input:not(:valid), .e-input:valid, .e-float-input.e-control-wrapper input:not(:valid), .e-float-input.e-control-wrapper input:valid, .e-float-input input:not(:valid), .e-float-input input:valid, .e-input-group input:not(:valid), .e-input-group input:valid, .e-input-group.e-control-wrapper input:not(:valid), .e-input-group.e-control-wrapper input:valid, .e-float-input.e-control-wrapper textarea:not(:valid), .e-float-input.e-control-wrapper textarea:valid, .e-float-input textarea:not(:valid), .e-float-input textarea:valid, .e-input-group.e-control-wrapper textarea:not(:valid), .e-input-group.e-control-wrapper textarea:valid, .e-input-group textarea:not(:valid), .e-input-group textarea:valid {
    font-size: 16px;
    height: 40px;
    color: rgb(96,172,86);
    font-weight: bolder;

}