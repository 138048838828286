.header {
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  justify-content: center;
  border-bottom: 0.1px solid rgb(96, 172, 86);
  padding-top: 5px;
  padding-bottom: 0px;
  align-items: center;
  width: 100vw;
  background-color: whitesmoke;
  color: rgb(96, 172, 86);
  min-width: 1500px;
}

.header_left {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-left: 20px;
  width: 15%;
}
.header_left > img {
  object-fit: contain;
  height: 60px;
  justify-content: center;
}
.header_left > h1 {
  font-size: 20px;
  margin-left: 10px;
}
.header_right {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
  border-left: 0.1px solid rgb(96, 172, 86);
  border-right: 0.1px solid rgb(96, 172, 86);
  width: 70%;
}

.header_right a {
  text-decoration: none !important;
  color: rgb(96, 172, 86) !important;
}
.header_right a.active {
  color: rgb(96, 172, 86);
  background-color: rgba(96, 172, 86, 20%);
  border-radius: 10px;
}

.header_user_section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  /* margin-left: 20px; */
  width: 20%;
}

.notif-section {
  cursor: pointer;
}
.header_user {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
  /* margin-left: 20px; */
  /* width: 15%; */
}
.header_user:hover {
  cursor: pointer;
}

.header_user > img {
  object-fit: contain;
  height: 60px;
  justify-content: center;
  border-radius: 50%;
}
.userInfo > h1 {
  font-size: 16px;
  margin-left: 10px;
}
.userInfo > p {
  font-size: 14px;
  margin-left: 10px;
}

.logout_user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgb(96, 172, 86);
}

.logout_user > img {
  object-fit: contain;
  height: 150px;
  justify-content: center;
  border-radius: 50%;
}
.logoutInfo > h1 {
  font-size: 20px;
  margin-left: 10px;
}
.logoutInfo > p {
  font-size: 14px;
  margin-left: 10px;
  color: whitesmoke;
}
.logoutInfo > button {
  font-size: 14px;
  margin-left: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
  color: rgb(96, 172, 86);
  cursor: pointer;
  border: none;
}

/*----------DIALOG-------------------------------------*/

.noti_outer_container {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noti_outer_container > h1 {
  margin-bottom: 20px;
  align-items: center;
  color: rgb(96, 172, 86);
  font-size: x-large;
}

.noti_outer_container > button {
  margin-bottom: 20px;
  align-items: center;
  color: rgb(96, 172, 86);
  display: flex;
  padding: 5px 10px;
  gap: 2px;
  border: none;
  border-radius: 5px;
  font-size: 20px !important;
}

.noti_outer_container button:hover {
  background-color: lightgray;
  color: black;
}

.noti_inner_container {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 10px;
}

.noti_inner_container > button {
  background-color: rgb(96, 172, 86);
  color: whitesmoke;
  border-radius: 10px;
  padding: 5px;
  border: none;
  text-align: left;
  cursor: pointer;
}
