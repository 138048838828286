/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
* {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
} */

/* Styling the main container */
.container {
  width: 95%;
  margin: auto;
  /* margin-top: 2rem; */
  letter-spacing: 0.5px;
  height: 95%;
}

.msg-img {
  width: 50px;
  vertical-align: middle;
  border-style: none;
  border-radius: 100%;
}
/* Styling the msg-header container */
.msg-header {
  border: 1px solid #ccc;
  width: 100%;
  height: 10%;
  border-bottom: none;
  display: inline-block;
  background-color: #efefef;
  margin: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
/* Styling the profile picture */
.msgimg {
  margin-left: 2%;
  float: left;
}

.container1 {
  width: 270px;
  height: auto;
  float: left;
  margin: 0;
}

/* styling user-name */
.active {
  width: 150px;
  float: left;
  color: black;
  font-weight: bold;
  margin: 0 0 0 5px;
  height: 10%;
}
/* Styling the inbox */
.chat-page {
  padding: 0 0 50px 0;
  height: 100%;
}

.msg-inbox {
  border: 1px solid #ccc;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chats {
  padding: 0 15px 0 25px;
  overflow-y: auto;
}

.msg-page {
  height: 650px;
  overflow-y: auto;
}

/* Styling the msg-bottom container */
.msg-bottom {
  border-top: 1px solid #ccc;
  /* position: relative; */
  height: 50px;
  background-color: rgb(239 239 239);
}
/* Styling the input field */
.msg-input-group {
  /* float: right; */
  margin-top: 5px;
  margin-bottom: auto;
  outline: none !important;
  border-radius: 20px;
  width: 100% !important;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.msg-input-group > .msg-form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.msg-form-control {
  border: none !important;
  border-radius: 20px !important;
  display: block;
  height: calc(2.25rem + 2px);
  /* padding: 0.375rem 0.75rem; */
  padding-left: 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group-text {
  background: transparent !important;
  border: none !important;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: b;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-weight: bold !important;
  cursor: pointer;
}

input:focus {
  outline: none;
  border: none !important;
  box-shadow: none !important;
}

.send-icon {
  font-weight: bold !important;
}

/* Styling the avatar  */
.received-chats-img {
  font-size: 12px;
  margin-bottom: 20px;
}

.received-chats {
  display: flex;
  align-items: center;
  margin: 26px 20px;
}
.received-msg {
  display: inline-block;
  /* padding: 0 0 0 10px; */
  vertical-align: top;
  width: 92%;
}
.received-msg-inbox {
  width: 57%;
}

.received-msg-inbox p {
  background: rgba(96, 172, 86, 15%) none repeat scroll 0 0;
  border-radius: 10px;
  color: #646464;
  font-size: 14px;
  margin-left: 10px;
  padding: 1rem;
  width: 100%;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 5px 2px;
}
p {
  overflow-wrap: break-word;
}

/* Styling the msg-sent time  */
.time {
  color: #777;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.outgoing-chats {
  overflow: hidden;
  margin: 26px 20px;
}
.outgoing-chats-msg p {
  background-color: rgba(96, 172, 86, 80%);
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
  padding: 1rem;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 5px 2px;
}
.outgoing-chats-msg {
  float: right;
  width: 46%;
  margin-right: 40px;
}

/* Styling the avatar */
.outgoing-chats-img {
  display: inline-block;
  width: 50px;
  font-size: 12px;
  float: right;
}
@media only screen and (max-device-width: 850px) {
  *,
  .time {
    font-size: 28px;
  }
  img {
    width: 65px;
  }
  .msg-header {
    height: 5%;
  }
  .msg-page {
    max-height: none;
  }
  .received-msg-inbox p {
    font-size: 28px;
  }
  .outgoing-chats-msg p {
    font-size: 28px;
  }
}

@media only screen and (max-device-width: 450px) {
  *,
  .time {
    font-size: 28px;
  }
  img {
    width: 65px;
  }
  .msg-header {
    height: 5%;
  }
  .msg-page {
    max-height: none;
  }
  .received-msg-inbox p {
    font-size: 28px;
  }
  .outgoing-chats-msg p {
    font-size: 28px;
  }
}
