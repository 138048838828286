.main-ben-container-details{
    border: 0.5px solid black;
    height: fit-content;
}

.beneficiary-field-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    gap: 10px;

    height: fit-content;
    padding-bottom: 30px;
    background-color: rgba(201,201,201,47%);
}

.my-cap-ben-row1,
.my-cap-ben-row2,
.my-cap-ben-row3,
.my-cap-ben-row4,
.my-cap-ben-row5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 98%;
    margin-top: 30px;
}
.my-cap-ben-row6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 49%;
    margin-top: 30px;
    margin-left: 0px;
    padding-left: 0px;
}
.field_ben_id{
    flex: 0.75;
    margin-left: 20px;
}
.field_project{
    flex: 3;
    margin-left: 20px;
}
.field_projectnum{
    flex: 2;
    margin-left: 20px;
}
.field_initials{
    flex: 0.75;
    margin-left: 20px;
}
.field_first_names{
    flex: 3;
    margin-left: 20px;
}
.field_surname{
    flex: 2;
    margin-left: 20px;
}
.field_id_number{
    flex: 1;
    margin-left: 20px;
}
.field_date_of_birth{
    flex: 1;
    margin-left: 20px;
}
.field_gender{
    flex: 1;
    margin-left: 20px;
}
.field_race{
    flex: 1;
    margin-left: 20px;
}
.field_home_language{
    flex: 1;
    margin-left: 20px;
}

.field_cell_number{
    flex: 1;
    margin-left: 20px;
}
.field_status{
    flex: 1;
    margin-left: 20px;
}
.field_email_address{
    flex: 3;
    margin-left: 20px;
}
.field_ben_physcal_address{
    flex: 5;
    margin-left: 20px;
}
.field_ben_postal_address{
    flex: 5;
    margin-left: 20px;
}
.field_bank_name{
    flex: 1;
    margin-left: 20px;
}
.field_account_holder{
    flex: 1;
    margin-left: 20px;
}
.field_account_number{
    flex: 1;
    margin-left: 20px;
}
.field_brank_code{
    flex: 1;
    margin-left: 20px;
}
.field_account_number{
    flex: 1;
    margin-left: 20px;
}
.field_brank_code{
    flex: 1;
    margin-left: 20px;
}
.field_branch_name{
    flex: 1;
    margin-left: 20px;
}
.field_account_type{
    flex: 1;
    margin-left: 20px;
}
/* To specify height and font size */
.e-input:not(:valid), .e-input:valid, .e-float-input.e-control-wrapper input:not(:valid), .e-float-input.e-control-wrapper input:valid, .e-float-input input:not(:valid), .e-float-input input:valid, .e-input-group input:not(:valid), .e-input-group input:valid, .e-input-group.e-control-wrapper input:not(:valid), .e-input-group.e-control-wrapper input:valid, .e-float-input.e-control-wrapper textarea:not(:valid), .e-float-input.e-control-wrapper textarea:valid, .e-float-input textarea:not(:valid), .e-float-input textarea:valid, .e-input-group.e-control-wrapper textarea:not(:valid), .e-input-group.e-control-wrapper textarea:valid, .e-input-group textarea:not(:valid), .e-input-group textarea:valid {
    font-size: 16px;
    height: 40px;
    color: rgb(96,172,86);
    font-weight: bolder;

}