.reports-container {
    display: flex;
    padding: 10px 0px;
    border-radius: 15px; 
    flex-direction: column; /* Align chart rows vertically */
    align-items: center; /* Center horizontally within the container */
    height: fit-content; /* Set the height to 90% of the viewport height */
    color: rgb(96,172,86);
    background-color: rgba(96,172,86, 20%);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }

.national-reports-container1,
.provincial-reports-container1,
.regional-reports-container1 {
    display: flex;
    padding: 0px 0px; 
    flex-direction: column; /* Align chart rows vertically */
    align-items: center; /* Center horizontally within the container */
    height: fit-content; /* Set the height to 90% of the viewport height */
    color: rgb(96,172,86);
    background-color: none;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }
  
  .chart-row {
    display: flex; /* Align chart containers side by side */
    gap: 10px; /* Add horizontal space between chart containers */
    width: 98%;
    height: 50%;
    padding-bottom: 10px;
  }
  
  .chart-container1,
  .chart-container2,
  .chart-container3,
  .chart-container4,
  .chart-container5, 
  .chart-container6, 
  .chart-container7, 
  .chart-container8, 
  .chart-container9, 
  .chart-container10, 
  .chart-container11,
  .chart-container12, 
  .chart-container13, 
  .chart-container14, 
  .chart-container15  {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    background-color:whitesmoke;
    border-radius: 15px;   
  }
  .chart-container1 {
    flex: 1;  
  }
  .chart-container2 {
    flex: 2;
  }
  .chart-container3 {
    flex: 3;
  }
  .chart-container4 {
    flex: 2;
  }
  .chart-container5 {
    flex: 5;
  }
  .chart-container6 {
    width: 100%;
  }

  .chart-container7 {
    flex: 1;  
  }
  .chart-container8 {
    flex: 2;
  }
  .chart-container9 {
    flex: 3;
  }
  .chart-container10 {
    flex: 2;
  }
  .chart-container11 {
    flex: 5;
  }

  .chart-container12 {
    flex: 1;
  }
  .chart-container13 {
    flex: 2;
  }
  .chart-container14 {
    flex: 2;
  }
  .chart-container15 {
    width: 100%;
  }

  .chart-container h2,
  .chart-row h2 {
    margin: 0;
  }
  .combo-box {
    width: 150px;
    font-size: 16px;
  }