/*----------DIALOG-------------------------------------*/

.message_outer_container {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message_outer_container > h1 {
  margin-bottom: 20px;
  align-items: center;
  color: rgb(96, 172, 86);
  font-size: x-large;
}

.message_outer_container > button {
  margin-bottom: 20px;
  align-items: center;
  color: rgb(96, 172, 86);
  display: flex;
  padding: 5px 10px;
  gap: 2px;
  border: none;
  border-radius: 5px;
  font-size: 20px !important;
}

.message_outer_container button:hover {
  background-color: lightgray;
}

.message_inner_container {
  width: 100%;
  height: 90%;
}
