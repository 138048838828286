.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(252, 221, 178, 20%);
    color: rgb(96,172,86);
  }
  
  .login-form, .forgot-password, .change-password {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 20%;
    min-width: 300px;
    background-color: rgba(252, 221, 178, 20%);
    color: rgb(96,172,86);
    border: 0.7px solid grey; 
    border-radius: 20px;
    padding: 20px;
    justify-content: center;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
  }
  
  .input-group {
    margin-bottom: 10px;
    font-size: 20px;
    border: none;
  }
  
  .username {
    margin-bottom: 10px;
    height: 30px;
    padding-left: 10px;
    border-radius: 7px;
    width: 100%;
    border: 0.2px solid gray;
    color: black;
  }
  .email {
    margin-bottom: 10px;
    height: 30px;
    padding-left: 10px;
    border-radius: 7px;
    width: 100%;
    border: 0.2px solid gray;
  }

  .password {
    margin-bottom: 10px;
    height: 30px;
    padding-left: 10px;
    border-radius: 7px;
    width: 100%;
    border: 0.2px solid gray;
  }

  .login-button, .change-button {
    margin: 10px;
    width: 60%;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    background-color: rgb(96,172,86);
    color: whitesmoke;
    border-radius: 7px;
    font-size: 18px !important;
    border: 0.2px solid gray;
  }

  .login-button:hover,
  .change-button:hover {
    background-color: rgba(96, 172, 86, 0.8); /* Lighter color on hover */
    cursor: pointer;
  }

  .login-button:active,
  .change-button:active {
    background-color: rgba(96, 172, 86, 0.6); /* Slightly darker color on click */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow on click */
}

  .login-button2, .change-button2 {
    margin: 10px;
    width: fit-content;
    padding-left: 10px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    background-color: rgba(96, 172, 86, 0.15); /* Slightly darker color on click */
    font-size: 17px !important;
    border: none;
    color: rgba(0, 0, 255, 60%);
  }

  .login-button2:hover,
  .change-button2:hover {
    background-color: rgba(252, 221, 178, 20%);
    cursor: pointer;
  }

  .login-button2:active,
  .change-button2:active {
    background-color: rgba(96, 172, 86, 0.6); /* Slightly darker color on click */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow on click */
  }
  .success-message {
    color: green;
    margin-bottom: 10px;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }

  .login-form> img, .forgot-password> img, .change-password> img{
    object-fit: contain;
    height: 200px;
    justify-content: center;

}
.login-form> h2, .forgot-password> h2, .change-password> h2{
    font-size: xx-large;
    padding-bottom: 20px;
    padding-top: 40px;

}