.main-chat-container{
    width: 100%;
    display: flex;
    min-height: 800px;
    gap: 20px;
    margin-right: 10px;
    flex-direction: row;
    align-items: center; /* Horizontally center content */
    justify-content: center; /* Vertically center content */
    height: 100%;
}
.item-chat-container >h3,
.chat-container >h3,
.chat-group-container >h3{
    background-color: rgba(201,201,201,47%) !important;
    font-size: 15px;
    color: rgb(96,172,86);
    width: 100%;
    margin-top: 0;
    padding: 10px 0px;
    text-align: center; /* Center the text within h3 tags */
}
.item-chat-container{
    width: 50%;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center content */
    height: 100%;
}
.chat-group-container{
    width: 30%;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center content */
    height: 100%;
}
.chat-container{
    width: 50%;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center content */
    height: 100%;
}
.inner_container {
  width: 100%;
  height: 100%;
}

.text-bold {
    font-weight: bold;
}

.list-message {
    background-color: antiquewhite;
    padding: 8px;
    width: 100%;
    cursor: pointer;
}

.list-message-size {
    font-size: 14px;
}

.user-div {
    padding-top: 5px;
    font-weight: 500;
}