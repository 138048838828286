.my-workflows >h3,
.workflow-history-my-workflows >h3{
    background-color: rgba(201,201,201,47%) !important;
    font-size: 15px;
    color: rgb(96,172,86);
    width: 100%;
    padding: 10px 0px;
    text-align: center; /* Center the text within h3 tags */
}

.project-my-workflows-container{
    width: 68%;
    height: 590px;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center content */
    min-height: 80vh; /* Ensure the container takes up at least the full viewport height */
}

.workflow-history-my-workflows{
    width: 28%;
    margin-left: 10px;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    height: 100%;
}

.main-my-workflows-container{
    width: 100%;
    height: 600px;
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: center; /* Horizontally center content */
    justify-content: center; /* Vertically center content */
    min-height: 80vh; /* Ensure the container takes up at least the full viewport height */
}

.my-workflows {
  box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
  width: 100%;
  height: 100%;
}
.button-container {
    display: flex;
    gap: 60px;
    
  }
  
  .button-container-sub2 {
    display: flex;
    gap: 10px;    
  }
  
  .button-container-sub2 button {
    display: flex;
    align-items: center; /* Center vertically */
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px !important;
  }

  .recall-button {
    color:red ;
  }

  .button-container-sub2 button:hover {
    background-color: lightgray; /* Change the background color to grey on hover */
  }
  
  .coordinator-button {
    color:rgb(86, 96, 172) ;
  }
  
  .administrator-button {
    color: rgb(86, 172, 96);
  }
  
  .facilitator-button {
    color: rgb(172, 86, 172);
  }
  
  .national-facilitator-button {
    color: rgb(86, 172, 172);
  }
  
  .assistant-nf-button {
    color: rgb(172, 172, 86);
  }
  .send-icon{
    margin-right: 10px;
    font-size: 16PX !important;
  }