.main-outer-container-detail1{
    border: 0.5px solid black;
    height: 670px;
}

.main-inner-container-detail1{
    display: flex;
    flex-direction: column;
    width: 99%;
    margin-left: 5px;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    gap: 10px;
    align-items: center; /* Horizontally center content */
    height: fit-content;
    padding-bottom: 30px;
    background-color: rgba(201,201,201,47%);
}

.my-project-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 98%;
    margin-top: 30px;
}
.main-proj-id{
    flex: 1;
    margin-left: 20px;
}
.main-proj-name{
    flex: 4;
    margin-left: 20px;
}
.main-proj-num{
    flex: 2;
    margin-left: 20px;
}
.main-proj-type{
    flex: 1;
    margin-left: 20px;
}
.main-proj-phase{
    flex: 2;
    margin-left: 20px;
}
.main-proj-stage{
    flex: 1;
    margin-left: 20px;
}
.main-proj-provice{
    flex: 1;
    margin-left: 20px;
}
.main-proj-region{
    flex: 1;
    margin-left: 20px;
}
.main-proj-facilitator{
    flex: 1;
    margin-left: 20px;
}
.main-proj-administrator{
    flex: 1;
    margin-left: 20px;
}
.main-proj-coordinator{
    flex: 1;
    margin-left: 20px;
}
.main-proj-status{
    flex: 1;
    margin-left: 20px;
}
.main-proj-sector{
    flex: 1;
    margin-left: 20px;
}
.main-proj-subsector{
    flex: 1;
    margin-left: 20px;
}
.main-proj-comment{
    flex: 20;
    margin-left: 20px;
}


.main-proj-normal_budget{
    flex: 1;
    margin-left: 20px;
}
.main-proj-dis_required{
    flex: 1;
    margin-left: 20px;
}
.main-proj-amount_required{
    flex: 1;
    margin-left: 20px;
}
.main-proj-dis_motivation{
    flex: 5;
    margin-left: 20px;
}
.main-proj-identification_date{
    flex: 1;
    margin-left: 20px;
}
.main-proj-verification_date{
    flex: 1;
    margin-left: 20px;
}
.main-proj-capacity_date{
    flex: 1;
    margin-left: 20px;
}
.main-proj-funding_date{
    flex: 1;
    margin-left: 20px;
}
.main-proj-monitoring_date{
    flex: 1;
    margin-left: 20px;
}
/* To specify height and font size */
.e-input:not(:valid), .e-input:valid, .e-float-input.e-control-wrapper input:not(:valid), .e-float-input.e-control-wrapper input:valid, .e-float-input input:not(:valid), .e-float-input input:valid, .e-input-group input:not(:valid), .e-input-group input:valid, .e-input-group.e-control-wrapper input:not(:valid), .e-input-group.e-control-wrapper input:valid, .e-float-input.e-control-wrapper textarea:not(:valid), .e-float-input.e-control-wrapper textarea:valid, .e-float-input textarea:not(:valid), .e-float-input textarea:valid, .e-input-group.e-control-wrapper textarea:not(:valid), .e-input-group.e-control-wrapper textarea:valid, .e-input-group textarea:not(:valid), .e-input-group textarea:valid {
    font-size: 16px;
    height: 40px;
    color: rgb(96,172,86);
    font-weight: bolder;

}