.reports-container {
    display: flex;
    padding: 10px 0px;
    border-radius: 15px; 
    flex-direction: column; /* Align chart rows vertically */
    align-items: center; /* Center horizontally within the container */
    gap: 10px; /* Add vertical space between chart rows */
    height: fit-content; /* Set the height to 90% of the viewport height */
    color: rgb(96,172,86);
    background-color: rgba(96,172,86, 20%);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }

.national-reports-container,
.provincial-reports-container,
.regional-reports-container {
    display: flex;
    padding: 10px 0px;
    border-radius: 15px; 
    flex-direction: column; /* Align chart rows vertically */
    align-items: center; /* Center horizontally within the container */
    gap: 10px; /* Add vertical space between chart rows */
    height: fit-content; /* Set the height to 90% of the viewport height */
    color: rgb(96,172,86);
    width: 98%;
    background-color: rgba(96,172,86, 20%);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }
  
  .chart-row {
    display: flex; /* Align chart containers side by side */
    gap: 10px; /* Add horizontal space between chart containers */
    width: 98%;
    height: 50%;
  }
  
  .chart-row1 {
    display: flex; /* Align chart containers side by side */
    gap: 10px; /* Add horizontal space between chart containers */
    width: 98%;
    height: 50%;
  }
  .chart-container {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    background-color:whitesmoke;
    border-radius: 15px;   
  }
  .chart-container1 {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    background-color:whitesmoke;
    border-radius: 15px;   
    width: 20%;
  }
  .chart-container2 {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    background-color:whitesmoke;
    border-radius: 15px;   
    width: 15%;
  }
  .chart-container3 {
    
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    background-color:whitesmoke;
    border-radius: 15px;   
    width: 65%;
  }
  .chart-container h2,
  .chart-row h2 {
    margin: 0;
  }
  