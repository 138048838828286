.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    background-color: whitesmoke;
}
.sidebar a.active {
    color: green !important;
    background-color: rgba(96, 172, 86, 20%) !important;
    border-radius: 10px !important;
    margin-left: 0px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 10px;
    height: fit-content;
}

.sidebar a {
    text-decoration: none !important;
    color: rgb(96, 172, 86) !important;
    background-color: whitesmoke;
    margin-left: 0px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 10px;
    height: fit-content;
}

.sidebar a:hover {
    color: green !important;
    border-radius: 10px !important;
    background-color: rgba(96, 172, 86, 20%) !important;
}
