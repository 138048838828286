.all-main-compliancy-containers{
  width: 98%;
  gap: 20px;
  align-items: center; /* Horizontally center content */
  justify-content: center; /* Vertically center content */
  height: 81vh; /* Ensure the container takes up at least the full viewport height */
}
.all-outer-compliancy-containers{
  width: 100%;
  margin-left: 5px;
  box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontally center content */
  height: 100%; /* Ensure the container takes up at least the full viewport height */
}
.all-outer-compliancy-containers >h3{
  background-color: rgba(201,201,201,47%) !important;
  font-size: 15px;
  color: rgb(96,172,86);
  width: 100%;
  padding: 10px 0px;
  text-align: center; /* Center the text within h3 tags */
}
.all-inner-compliancy-containers{
  width: 100%;
  box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
  align-items: center; /* Horizontally center content */
}

.button-container {
  display: flex;
  gap: 60px;
  
}
.button-container-subA1 {
  display: flex;
  gap: 10px;    
}
.button-container-subA1 button {
  display: flex;
  align-items: center; /* Center vertically */
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px !important;
}

.button-container-subA1 button:hover {
  background-color: lightgray; /* Change the background color to grey on hover */
}

.edit-button {
  color:rgb(172, 96, 86) ;
  cursor: pointer;
}

.button-container1 {
  display: flex;   
}

.button-container1 button {
  display: flex;
  align-items: center; /* Center vertically */
  padding: 5px 10px;
  gap: 5px;
  border: none;
  border-radius: 5px;
  font-size: 14px !important;
}

.button-container1 button:hover {
  background-color: lightgray;
  cursor: pointer;
}

.status-active {
  color: green;
}

.status-inactive {
  color: orange;
}

.status-delayed {
  color: red;
}

