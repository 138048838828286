.main-container-detail{
    border: 0.5px solid black;
    height: 800px;
}

.project-field-container{
    display: flex;
    flex-direction: column;
    width: 99%;
    margin-left: 5px;
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,47%);
    gap: 10px;
    align-items: center; /* Horizontally center content */
    min-height: 800px; /* Ensure the container takes up at least the full viewport height */
    background-color: rgba(201,201,201,47%);
}

.my-id-row1,
.my-id-row2,
.my-id-row3,
.my-id-row4,
.my-id-row5 {
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 98%;
  margin-top: 30px;
}
.field_id{
    flex: 1;
    margin-left: 20px;
}
.field_project{
    flex: 4;
    margin-left: 20px;
}
.field_projectnum{
    flex: 2;
    margin-left: 20px;
}
.field_type{
    flex: 1;
    margin-left: 20px;
}
.field_phase{
    flex: 2;
    margin-left: 20px;
}
.field_stage{
    flex: 2;
    margin-left: 20px;
}
.field_provice{
    flex: 1;
    margin-left: 20px;
}
.field_provice{
    flex: 1;
    margin-left: 20px;
}
.field_region{
    flex: 1;
    margin-left: 20px;
}
.field_facilitator{
    flex: 1;
    margin-left: 20px;
}
.field_administrator{
    flex: 1;
    margin-left: 20px;
}
.field_coordinator{
    flex: 1;
    margin-left: 20px;
}
.field_sector{
    flex: 1;
    margin-left: 20px;
}
.field_subsector{
    flex: 1;
    margin-left: 20px;
}
.field_identification_date{
    flex: 1;
    margin-left: 20px;
}
.field_verification_date{
    flex: 1;
    margin-left: 20px;
}
.field_status{
    flex: 1;
    margin-left: 20px;
}
.field_town{
    flex: 1;
    margin-left: 20px;
}
.field_physcal_address{
    flex: 3;
    margin-left: 20px;
}
.field_postal_address{
    flex: 3;
    margin-left: 20px;
}
.field_general_comment{
    flex: 20;
    margin-left: 20px;
}

/* To specify height and font size */
.e-input:not(:valid), .e-input:valid, .e-float-input.e-control-wrapper input:not(:valid), .e-float-input.e-control-wrapper input:valid, .e-float-input input:not(:valid), .e-float-input input:valid, .e-input-group input:not(:valid), .e-input-group input:valid, .e-input-group.e-control-wrapper input:not(:valid), .e-input-group.e-control-wrapper input:valid, .e-float-input.e-control-wrapper textarea:not(:valid), .e-float-input.e-control-wrapper textarea:valid, .e-float-input textarea:not(:valid), .e-float-input textarea:valid, .e-input-group.e-control-wrapper textarea:not(:valid), .e-input-group.e-control-wrapper textarea:valid, .e-input-group textarea:not(:valid), .e-input-group textarea:valid {
    font-size: 16px;
    height: 40px;
    color: rgb(96,172,86);
    font-weight: bolder;

}