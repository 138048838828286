
.home-regional-reports-container{
    display: flex;
    padding: 0px 0px; 
    flex-direction: column; /* Align chart rows vertically */
    align-items: center; /* Center horizontally within the container */
    height: fit-content; /* Set the height to 90% of the viewport height */
    color: rgb(96,172,86);
    background-color: rgba(96,172,86, 20%);
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }
  
  .prochart-row5,
  .prochart-row6,
  .prochart-row7 {
    display: flex;
    gap: 10px;
    width: 98%;
    padding: 10px;
  }
  
  .chart-cont10,
  .chart-cont11,
  .chart-cont12,
  .chart-cont13,
  .chart-cont14, 
  .chart-cont15, 
  .chart-cont16, 
  .chart-cont17,  
  .summary-container3,
  .summary-container4  {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    background-color:whitesmoke;
    border-radius: 15px; 
    color: rgba(96,172,86, 60%);  
  }
  .chart-cont10 {
    flex: 1;  
  }
  .chart-cont11 {
    flex: 1;
  }
  .chart-cont12 {
    flex: 1;
  }
  .chart-cont13 {
    flex: 1;
  }
  .chart-cont14 {
    flex: 1;
  }
  .chart-cont15 {
    flex: 3;
  }

  .chart-cont16 {
    flex: 1;  
  }
  .chart-cont17 {
    flex: 1;
  }
  .summary-container3{
    flex: 1.5;
    margin-top: 15px;
  }

  .summary-container4{
    flex: 2;
    margin-top: 15px;
  }

  .summary-container3 h1,
  .summary-container4 h1{
    margin-top: 15px;
    margin-bottom: 10px;
    color: #008080;
  }

  .summary-container3 h2,
  .summary-container4 h2{
    color: rgba(96,172,86, 50%);
  }