.reports-task-container {
  display: flex;
  padding: 10px 0px;
  border-radius: 15px; 
  flex-direction: column; /* Align chart rows vertically */
  align-items: center; /* Center horizontally within the container */
  gap: 10px; /* Add vertical space between chart rows */
  height: fit-content; /* Set the height to 90% of the viewport height */
  color: rgb(96,172,86);
  background-color: rgba(96,172,86, 20%);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.national-reports-task-container,
.provincial-reports-task-container,
.regional-reports-task-container {
  display: flex;
  padding: 10px 0px;
  border-radius: 15px; 
  flex-direction: column; /* Align chart rows vertically */
  align-items: center; /* Center horizontally within the container */
  gap: 10px; /* Add vertical space between chart rows */
  height: fit-content; /* Set the height to 90% of the viewport height */
  color: rgb(96,172,86);
  width: 98%;
  background-color: rgba(96,172,86, 20%);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  }
  
  .chart-task-row {
    display: flex; /* Align chart containers side by side */
    gap: 10px; /* Add horizontal space between chart containers */
    width: 98%;
    justify-content: center;
    align-items: center; /* Center horizontally within the container */
    height: 50%; 
  }
  
  .chart-task-container1,
  .chart-task-container2,
  .chart-task-container3,
  .chart-task-container4,
  .chart-task-container5, 
  .chart-task-container6, 
  .chart-task-container7, 
  .chart-task-container8, 
  .chart-task-container9, 
  .chart-task-container10, 
  .chart-task-container11,
  .chart-task-container12  {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    background-color:whitesmoke;
    border-radius: 15px;   
  }
  .chart-task-container1 {
    flex: 1;  
  }
  .chart-task-container2 {
    flex: 3;
  }
  .chart-task-container3 {
    flex: 3;
  }
  .chart-task-container4 {
    width: 100%;
  }
  .chart-task-container5 {
    width: 100%;
  }
  .chart-task-container6 {
    flex: 1;
  }
  .chart-task-container7 {
    flex: 4;  
  }
  .chart-task-container8 {
    flex: 1;
  }
  .chart-task-container9 {
    flex: 3;
  }
  .chart-task-container10 {
    flex: 2;
  }
  .chart-task-container11 {
    flex: 5;
  }
  .chart-task-container12 {
    width: 100%;
  }
  .chart-task-container1 h2,
  .chart-task-container2 h2,
  .chart-task-container3 h2,
  .chart-task-container4 h2,
  .chart-task-container5 h2, 
  .chart-task-container6 h2, 
  .chart-task-container7 h2, 
  .chart-task-container8 h2, 
  .chart-task-container9 h2, 
  .chart-task-container10 h2, 
  .chart-task-container11 h2,
  .chart-task-container12 h2,
  .chart-task-row h2 {
    margin: 0;
    color: rgba(96,172,86, 60%); 
  }
  .combo-box {
    width: 150px;
    font-size: 16px;
    margin-bottom: 10px;
  }
