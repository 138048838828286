.projectOption {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 10px;
    width: 100%;
}

.projectOption:hover {
    color: green !important;
    border-radius: 10px !important;
}

.projectOption_title {
    margin-top: 5px;
    width: 75%; /* Allow the full width of the component for the title */
    white-space: normal; /* Ensure that text doesn't truncate */
    align-items: center;
    justify-content: center;
    text-align: center;
}

.custom_icon {
    font-size: 30px !important;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.projectOption h3 {
    font-size: 13px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
